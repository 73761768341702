import moment from 'moment';
export function formatMoeda(valor) {
    return parseFloat(valor).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
    });
}

export function formatPercent(valor) {
    return parseFloat(valor).toLocaleString("pt-br", {
        style: "percent",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export function getContrastColor(hex) {
    if (hex) {
        hex = hex.replace('#', '');
        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness > 128 ? '#000000' : '#ffffff';
    }
    return '#000000';
}
export function formatData(data) {
    return moment(data).format('DD/MM/YYYY');
}