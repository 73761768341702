import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "../stores/auth";

export const authGuard = async (to, from, next) => {
  const authStore = useAuthStore();
  const isAuthenticated = await authStore.verifyStatus();
  if (!isAuthenticated) {
    next({ name: "LoginAdmin" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/logar",
    name: "Administracao",
    component: () => import("../admin/views/principal.vue"),
    redirect: "/login",
    children: [
      {
        path: "/login",
        name: "LoginAdmin",
        component: () => import("../admin/views/login/login.vue"),
      }
    ],
  },
  {
    path: "/",
    name: "LandingPage",
    component: () => import("../admin/views/landingPage.vue"),
    children: [
      {
        path: "",
        name: "InicioLandingPage",
        component: () => import("../admin/views/landingPage/PrincipalLandingPage.vue"),
      },
      {
        path: "/solucoes",
        name: "SolucoesLandingPage",
        component: () => import("../admin/views/landingPage/SolucoesLandingPage.vue"),
      },
      {
        path: "/planos",
        name: "PlanosLandingPage",
        component: () => import("../admin/views/landingPage/PlanosLandingPage.vue"),
      }
    ]
  },
  {
    path: "/home",
    name: "AdminHome",
    component: () => import("../admin/views/home/home.vue"),
    redirect: "/inicio",
    beforeEnter: authGuard,
    children: [
      {
        path: "/ajuda",
        name: "Ajuda",
        component: () => import("../admin/views/ajuda/PrincipalAjuda.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/pedido/:id",
        name: "PedidosNovo",
        component: () =>
          import("../admin/views/pedido/NovoPedidoOrcamento.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/inicio",
        name: "Inicio",
        component: () => import("../admin/views/inicio/inicio.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/pedidos",
        name: "Pedidos",
        component: () => import("../admin/views/pedido/PrincipalPedidos.vue"),
        beforeEnter: authGuard,
        redirect: "/pedidos/todos",
        children: [
          {
            path: "/pedidos/todos",
            name: "PedidosTodos",
            component: () => import("../admin/views/pedido/NovaListaPedido.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/pedidos/configuracoes/geral",
            name: "PedidosConfiguracoesGeral",
            component: () =>
              import("../admin/views/pedido/ConfiguracoesGeral.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/pedidos/configuracoes/status_pedido",
            name: "PedidosConfiguracoesStatusPedido",
            component: () => import("../admin/views/pedido/StatusPedido.vue"),
            beforeEnter: authGuard,
          },
        ],
      },
      {
        path: "/produtos",
        name: "Produtos",
        component: () => import("../admin/views/produto/PrincipalProduto.vue"),
        beforeEnter: authGuard,
        redirect: "/produtos/lista",
        children: [
          {
            path: "/produtos/lista",
            name: "ProdutosLista",
            component: () =>
              import("../admin/views/produto/ProdutosLista.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/produtos/tabelacidade",
            name: "ProdutosTabelaCidade",
            component: () =>
              import("../admin/views/produto/VinculoTabelaPrecoCidade.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/produtos/Tabelas",
            name: "ProdutosTabelas",
            component: () =>
              import("../admin/views/produto/CadastroTabelaPreco.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/produtos/importar_produtos",
            name: "ProdutosImportarProdutos",
            component: () =>
              import("../admin/views/produto/ImportarProdutos.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/produtos/importar_fotos",
            name: "ProdutosImportarFotos",
            component: () => import("../admin/views/produto/ImportarFotos.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/produtos/categorias",
            name: "ProdutosCategorias",
            component: () =>
              import("../admin/views/categoria/PrincipalCategoria.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/produtos/tributacoes",
            name: "ProdutosTributacoes",
            component: () =>
              import("../admin/views/tributacoes/PrincipalICMS.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/produtos/municipios",
            name: "ProdutosMunicipios",
            component: () =>
              import("../admin/views/ibge/PrincipalMunicipios.vue"),
            beforeEnter: authGuard,
          },
        ],
      },
      {
        path: "/clientes",
        name: "Clientes",
        component: () => import("../admin/views/cliente/PrincipalCliente.vue"),
        beforeEnter: authGuard,
        redirect: "/clientes/lista",
        children: [
          {
            path: "/clientes/lista",
            name: "ClientesLista",
            component: () => import("../admin/views/cliente/ListaClientes.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/clientes/configuracoes",
            name: "ClientesConfiguracoes",
            component: () =>
              import("../admin/views/ibge/PrincipalMunicipios.vue"),
            beforeEnter: authGuard,
          },
        ],
      },

      {
        path: "/configuracoes",
        name: "Configuracoes",
        component: () =>
          import("../admin/views/configuracoes/PrincipalConfiguracoes.vue"),
        redirect: "/configuracoes/usuario/todos",
        beforeEnter: authGuard,
        children: [
          {
            path: "/configuracoes/usuario/todos",
            name: "ListaUsuario",
            component: () =>
              import("../admin/views/configuracoes/ListaUsuarios.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/configuracoes/perfil",
            name: "PerfilConfiguracoes",
            component: () =>
              import("../admin/views/configuracoes/PerfilConfiguracoes.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "/configuracoes/sistema/aplicativo",
            name: "ConfiguracaoAplicativo",
            component: () =>
              import(
                "../admin/views/configuracoes/AplicativosConfiguracoes.vue"
              ),
            beforeEnter: authGuard,
          },
          {
            path: "/configuracoes/sistema/envio_email",
            name: "ConfiguracaoEnvioEmail",
            component: () =>
              import(
                "../admin/views/configuracoes/EnvioEmailConfiguracoes.vue"
              ),
            beforeEnter: authGuard,
          },
          {
            path: "/configuracoes/sistema/envio_email",
            name: "ConfiguracaoEnvioEmail",
            component: () =>
              import(
                "../admin/views/configuracoes/EnvioEmailConfiguracoes.vue"
              ),
            beforeEnter: authGuard,
          },
          {
            path: "/configuracoes/politicas/condicao_pagamento",
            name: "ConfiguracaoCondicaoPagamento",
            component: () =>
              import(
                "../admin/views/configuracoes/CondicaoPagamentoConfiguracoes.vue"
              ),
            beforeEnter: authGuard,
          },
          {
            path: "/configuracoes/equipes/todos",
            name: "ConfiguracaoEquipesTodos",
            component: () =>
              import("../admin/views/configuracoes/CadastroEquipe.vue"),
            beforeEnter: authGuard,
          },
        ],
      },
      {
        path: "/configuracoes/usuario/",
        name: "CadastroUsuario",
        component: () =>
          import("../admin/views/configuracoes/CadastroUsuario.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/configuracoes/usuario/:id",
        name: "CadastroUsuarioId",
        component: () =>
          import("../admin/views/configuracoes/CadastroUsuario.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/clientes/cadastro",
        name: "ClientesCadastro",
        component: () => import("../admin/views/cliente/CadastroCliente.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "/clientes/cadastro/:id",
        name: "ClientesCadastroId",
        component: () => import("../admin/views/cliente/CadastroCliente.vue"),
        beforeEnter: authGuard,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
