<template>
    <router-view />
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}



</style>
